<template>
  <div
    id="app"
    class="h-screen w-screen bg-base-100 max-w-full"
    :data-theme="currentTheme"
  >
    <div class="drawer lg:drawer-open">
      <input
        id="my-drawer-2"
        type="checkbox"
        class="drawer-toggle"
      >
      <div class="drawer-content flex flex-col">
        <!-- Page content here -->
        <label
          for="my-drawer-2"
          class="btn btn-primary drawer-button absolute lg:hidden"
        >
          <fa-icon icon="bars" />
        </label>
        <div class="flex flex-col justify-center bg-base-100">
          <section class="px-8 py-6 m-4 rounded-lg shadow-xl">
            <slot />
          </section>
        </div>
      </div>

      <nav class="drawer-side">
        <label
          for="my-drawer-2"
          class="drawer-overlay"
        />
        <div class="p-4 w-80 bg-base-100 text-base-content h-full">
          <ul class="menu">
            <li class="menu-title">
              <span>SMS</span>
            </li>
            <li>
              <NuxtLink to="/">
                <fa-icon
                  icon="comment-sms"
                  fixed-width
                />
                {{ $t('nav.sms') }}
              </NuxtLink>
            </li>
            <li>
              <NuxtLink to="/history">
                <fa-icon
                  icon="table-list"
                  fixed-width
                />
                {{ $t('nav.history') }}
              </NuxtLink>
            </li>
            <li>
              <a
                href="/openapi.html"
                target="_blank"
              >
                <fa-icon
                  icon="book"
                  fixed-width
                />
                {{ $t('nav.openapi') }}
              </a>
            </li>
            <li class="menu-title">
              <span>{{ $t('nav.categoryUser') }} {{ user.name }}</span>
            </li>
            <li>
              <a @click="toggleTheme">
                <fa-icon
                  icon="palette"
                  fixed-width
                />
                {{ $t('nav.theme') }}
              </a>
            </li>
            <li>
              <NuxtLink to="/language">
                <fa-icon
                  icon="language"
                  fixed-width
                />
                {{ $t('nav.language') }}
              </NuxtLink>
            </li>
            <li>
              <a href="/auth/api/logout">
                <fa-icon
                  icon="right-from-bracket"
                  fixed-width
                />
                {{ $t('nav.logout') }}
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script setup>

import {useCurrentUser} from "~/composables/states";

const user = useCurrentUser()
const currentColorScheme = ref(localStorage.getItem('theme') ?? "")
if (currentColorScheme.value === '') {
  // Load from prefers-color-scheme
  if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
    currentColorScheme.value = 'dark'
  } else {
    currentColorScheme.value = 'light'
  }
}

const currentTheme = ref(currentColorScheme.value === 'dark' ? 'business' : 'cupcake')

function toggleTheme() {
  if (currentColorScheme.value === 'light') {
    currentColorScheme.value = 'dark'
    currentTheme.value = 'business'
    localStorage.setItem('theme', 'dark')
  } else {
    currentColorScheme.value = 'light'
    currentTheme.value = 'cupcake'
    localStorage.setItem('theme', 'light')
  }
}

</script>
